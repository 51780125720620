const userTypes = {
  SET_EMAIL: 'SET_EMAIL',
  SET_LASTNAME: 'SET_LASTNAME',
  SET_FIRSTNAME: 'SET_FIRSTNAME',
  SET_MIDDLENAME: 'SET_MIDDLENAME',
  SET_SEX: 'SET_SEX',
  SET_STATE_OF_ORIGIN: 'SET_STATE_OF_ORIGIN',
  SET_ADDRESS: 'SET_ADDRESS',
  SET_PHONE: 'SET_PHONE',
  SET_DOB: 'SET_DOB',
  SET_WHATAPP: 'SET_WHATAPP',
  SET_NHF_REGISTRATION_NUMBER: 'SET_NHF_REGISTRATION_NUMBER',
  SET_WORK_EXPERIENCE: 'SET_WORK_EXPERIENCE',
  SET_YEAR_TO_RETIREMENT: 'SET_YEAR_TO_RETIREMENT',
  SET_MODE_OF_CONTACT: 'SET_MODE_OF_CONTACT',
  SET_NO_OF_DEPENDENTS: 'SET_NO_OF_DEPENDENTS',
  SET_PASSWORD: 'SET_PASSWORD',
  SET_CURRENT_APARTMENT_STATUS: 'SET_CURRENT_APARTMENT_STATUS',
  SET_MARITAL_STATUS: 'SET_MARITAL_STATUS',
  SET_EMPLOYMENT_ID: 'SET_EMPLOYMENT_ID',
  SET_EMPLOYMENT_PRESENT_POSITION: 'SET_EMPLOYMENT_PRESENT_POSITION',
  // SET_COMMAND: 'SET_COMMAND',
  SET_EMPLOYER_ADDRESS: 'SET_EMPLOYER_ADDRESS',
  SET_EMPLOYMENT_STATE: 'SET_EMPLOYMENT_STATE',
  // SET_IS_AUTHENTICATED: 'SET_IS_AUTHENTICATED',
};

export default userTypes;