const applicationTypes = {
  SET_APP_REF: 'SET_APP_REF',
  SET_TYPE: 'SET_TYPE',
  SET_EXISTING_LOANS: 'SET_EXISTING_LOANS',
  SET_PLACE_OF_BIRTH: 'SET_PLACE_OF_BIRTH',
  SET_SEX: 'SET_SEX',
  SET_NATIONALITY: 'SET_NATIONALITY',
  SET_HIGHEST_EDUCATION: 'SET_HIGHEST_EDUCATION',
  SET_STATE_OF_ORIGIN: 'SET_STATE_OF_ORIGIN',
  SET_ADDRESS: 'SET_ADDRESS',
  SET_PROFESSION: 'SET_PROFESSION',
  SET_EMPLOYER_NAME: 'SET_EMPLOYER_NAME',
  SET_EMPLOYER_ADDRESS: 'SET_EMPLOYER_ADDRESS',
  SET_EMPLOYER_PHONE: 'SET_EMPLOYER_PHONE',
  SET_PHONE: 'SET_PHONE',
  SET_EMPLOYER_EMAIL: 'SET_EMPLOYER_EMAIL',
  SET_EMPLOYMENT_PRESENT_POSITION: 'SET_EMPLOYMENT_PRESENT_POSITION',
  SET_WORK_EXPERIENCE: 'SET_WORK_EXPERIENCE',
  SET_YEAR_TO_RETIREMENT: 'SET_YEAR_TO_RETIREMENT',
  SET_OTHER_EMPLOYMENTS: 'SET_OTHER_EMPLOYMENTS',
  SET_OUTSTANDING_LOANS: 'SET_OUTSTANDING_LOANS',
  SET_CURRENT_APARTMENT_STATUS: 'SET_CURRENT_APARTMENT_STATUS',
  SET_ANNUAL_RENT_VALUE: 'SET_ANNUAL_RENT_VALUE',
  SET_MARITAL_STATUS: 'SET_MARITAL_STATUS',
  SET_SPOUSE_NAME: 'SET_SPOUSE_NAME',
  SET_SPOUSE_ADDRESS: 'SET_SPOUSE_ADDRESS',
  SET_SPOUSE_EMPLOYER: 'SET_SPOUSE_EMPLOYER',
  SET_SPOUSE_WORK_EXPERIENCE: 'SET_SPOUSE_WORK_EXPERIENCE',
  SET_SPOUSE_PROFESSION: 'SET_SPOUSE_PROFESSION',
  SET_SPOUSE_ANNUAL_INCOME: 'SET_SPOUSE_ANNUAL_INCOME',
  SET_CHILDREN: 'SET_CHILDREN',
  SET_HAVE_CHILDREN: 'SET_HAVE_CHILDREN',
  SET_NEXT_OF_KIN_NAME: 'SET_NEXT_OF_KIN_NAME',
  SET_NEXT_OF_KIN_RELATIONSHIP: 'SET_NEXT_OF_KIN_RELATIONSHIP',
  SET_NEXT_OF_KIN_AGE: 'SET_NEXT_OF_KIN_AGE',
  SET_NEXT_OF_KIN_ADDRESS: 'SET_NEXT_OF_KIN_ADDRESS',
  SET_TOTAL_ANNUAL_PAY: 'SET_TOTAL_ANNUAL_PAY',
  SET_MONTHLY_GROSS_PAY: 'SET_MONTHLY_GROSS_PAY',
  SET_MONTHLY_EXPENSES: 'SET_MONTHLY_EXPENSES',
  SET_HAVE_EXPENSES: 'SET_HAVE_EXPENSES',
  SET_OTHER_SOURCE_OF_INCOME: 'SET_OTHER_SOURCE_OF_INCOME',
  SET_AGREED_TO_TERMS: 'SET_AGREED_TO_TERMS',
  SET_HAVE_EMPLOYER_GUARANTOR: 'SET_HAVE_EMPLOYER_GUARANTOR',
  SET_EMPLOYMENT_IS_CONFIRMED: 'SET_EMPLOYMENT_IS_CONFIRMED',
  SET_MOTHER_MIDDLE_NAME: 'SET_MOTHER_MIDDLE_NAME',
  SET_MEANS_OF_IDENTIFICATION: 'SET_MEANS_OF_IDENTIFICATION',
  SET_ID_NUMBER: 'SET_ID_NUMBER',
  SET_ID_ISSUE_DATE: 'SET_ID_ISSUE_DATE',
  SET_ID_EXPIRE_DATE: 'SET_ID_EXPIRE_DATE',
  SET_PROPERTY_TITLE: 'SET_PROPERTY_TITLE',
  SET_PROPERTY_ADDRESS: 'SET_PROPERTY_ADDRESS',
  SET_PROPERTY_DESCRIPTION: 'SET_PROPERTY_DESCRIPTION',
  SET_EMPLOYER_NHF_REGISTRATION_NUMBER: 'SET_EMPLOYER_NHF_REGISTRATION_NUMBER',
  SET_GOT_TO_PREVIEW: 'SET_GOT_TO_PREVIEW'
};

export default applicationTypes;