const affordabilityTypes = {
  SET_AGE: 'SET_AGE',
  SET_RATE: 'SET_RATE',
  SET_BUDGET: 'SET_BUDGET',
  SET_TENURE: 'SET_TENURE',
  SET_IS_NHF: 'SET_IS_NHF',
  SET_MAX_TENURE: 'SET_MAX_TENURE',
  SET_PROPERTIES: 'SET_PROPERTIES',
  SET_LOAN_AMOUNT: 'SET_LOAN_AMOUNT',
  SET_PAYMENT_OPTION: 'SET_PAYMENT_OPTION',
  SET_PROPERTY_VALUE: 'SET_PROPERTY_VALUE',
  SET_MONTHLY_PAYMENT: 'SET_MONTHLY_PAYMENT',
  SET_OTHER_OBLIGATIONS: 'SET_OTHER_OBLIGATIONS',
  SET_HAVE_EQUITY: 'SET_HAVE_EQUITY',
  SET_MONTHLY_REPAYMENT: 'SET_MONTHLY_REPAYMENT',
  SET_MAX_LOANABLE_AMOUNT: 'SET_MAX_LOANABLE_AMOUNT',
  SET_EQUITY_CONTRIBUTION: 'SET_EQUITY_CONTRIBUTION',
  SET_HAVE_OTHER_OBLIGATIONS: 'SET_HAVE_OTHER_OBLIGATIONS',
  SET_MONTHLY_EXPENSES: 'SET_MONTHLY_EXPENSES',
  SET_OUTSTANDING_LOANS: 'SET_OUTSTANDING_LOANS'
};

export default affordabilityTypes;
